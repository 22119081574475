import React from 'react';

const ROLL_COUNT = 20;

class Wheel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "users": props.users,
            "isSpinning": false,
            "lastUser": false,
            "currentUser": false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const boundary = String.fromCharCode(...Array(8).fill(0).map(() => Math.floor(Math.random() * 64 + 32)));
        if (prevState.users.join(boundary) !== this.state.users.join(boundary)) {
            this.props.onUsersChange(this.state.users);
        }
        if (prevProps.users.join(boundary) !== this.props.users.join(boundary)) {
            this.setState({
                "users": this.props.users
            })
        }
    }

    roll = () => {
        if (this.state.users.length > 1) {
            let rollIndex = 0;
            const userRoll = () => {
                const {users, currentUser} = this.state;
                this.setState({
                    "currentUser": users.sort(() => Math.random() - 0.5).find(u => u !== currentUser)
                }, () => {
                    rollIndex++;
                    if (rollIndex < ROLL_COUNT) {
                        setTimeout(userRoll, 500 * (rollIndex / ROLL_COUNT));
                    } else {
                        this.setState({
                            "isSpinning": false,
                            "lastUser": this.state.currentUser,
                            "currentUser": false,
                            "users": this.state.users.filter(u => u !== this.state.currentUser)
                        })
                    }
                });
            };
            userRoll();
            this.setState({
                "isSpinning": true
            })
        } else if (this.state.users.length > 0) {
            this.setState({
                "lastUser": this.state.users[0],
                "users": []
            })
        } else {
            this.setState({
                "lastUser": false
            });
        }
    };

    render() {
        const {isSpinning, users, lastUser, currentUser} = this.state;
        console.log(this.state.lastUser);
        return (
            <div className="wheel">
                <div className="card">
                    <span className="next-user">
                        {currentUser !== false && currentUser}
                    </span>
                    <span className="result">
                        {currentUser === false && lastUser !== false && <>C'est au tour de <strong>{lastUser}</strong></>}
                    </span>
                    <span>
                        {isSpinning || users.length + " utilisateur(s) restant(s)"}
                    </span>
                </div>
                <button disabled={isSpinning} onClick={this.roll}>Who's next ?</button>
            </div>
        );
    }
}

export default Wheel;