import './App.css';
import UserManager from "./components/UserManager";
import React from "react";
import Wheel from "./components/Wheel";

class App extends React.Component {
    constructor(props) {
        super(props);
        const lastUsers = localStorage.getItem("users");
        this.state = {
            "users": lastUsers ? JSON.parse(lastUsers) : []
        };
    }

    updateUsers = users => {
        localStorage.setItem("users", JSON.stringify(users));
        this.setState({
            "users": users
        });
    };

    render() {
        const {users} = this.state;
        return (
            <div className="App">
                <Wheel users={users} onUsersChange={this.updateUsers}/>
                <UserManager users={users} onUsersChange={this.updateUsers}/>
                <div className="background">&nbsp;</div>
            </div>
        );
    }
}

export default App;
