import React from 'react';

class UserManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "users": props.users,
            "inputName": ""
        }
    }

    addUser = ev => {
        ev.preventDefault();
        if (this.state.inputName && !this.state.users.includes(this.state.inputName)) {
            this.setState({
                "users": [ev.target.username.value, ...this.state.users]
            });
            this.setState({
                "inputName": ""
            });
        }
    };

    delUser = index => {
        const {users} = this.state;
        users.splice(index, 1);
        this.setState({
            "users": users
        }, () => {
            this.props.onUsersChange(this.state.users);
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const boundary = String.fromCharCode(...Array(8).fill(0).map(() => Math.floor(Math.random() * 64 + 32)));
        if (prevState.users.join(boundary) !== this.state.users.join(boundary)) {
            this.props.onUsersChange(this.state.users);
        }
        if (prevProps.users.join(boundary) !== this.props.users.join(boundary)) {
            this.setState({
                "users": this.props.users
            })
        }
    }

    render() {
        const {users, inputName} = this.state;
        return (
            <div>
                <div className="userlist">
                    {
                        users.map((u, i) => <span key={i}>{u}<button onClick={() => this.delUser(i)}>&times;</button></span>)
                    }
                </div>
                <form onSubmit={this.addUser}>
                    <input
                        type="text"
                        name="username"
                        style={
                            users.includes(inputName) ?
                                {"borderColor": "red", "background-color": "#fdd"} :
                                {}
                        }
                        value={inputName}
                        onChange={ev => this.setState({"inputName": ev.target.value})}
                    />
                    <input type="submit" value="Add" />
                </form>
            </div>
        );
    }
}

export default UserManager;